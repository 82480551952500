var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"fictitious-booking-form"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('h6',[_vm._v("Allotjament")]),_c('v-select',{attrs:{"options":_vm.accommodationOptions,"reduce":function (option) { return option.value; },"loading":_vm.loadingAccommodations},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.accommodation),callback:function ($$v) {_vm.accommodation=$$v},expression:"accommodation"}})],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","sm":"6"}},[_c('h6',[_vm._v("Entrada")]),_c('b-form-datepicker',{attrs:{"placeholder":"Data d'entrada","locale":_vm.locale,"start-weekday":1,"show-decade-nav":false,"date-format-options":{
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        },"hide-header":""},model:{value:(_vm.checkin),callback:function ($$v) {_vm.checkin=$$v},expression:"checkin"}})],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('h6',[_vm._v("Sortida")]),_c('b-form-datepicker',{attrs:{"placeholder":"Data de sortida","locale":_vm.locale,"start-weekday":1,"show-decade-nav":false,"date-format-options":{
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        },"min":_vm.checkin,"hide-header":""},model:{value:(_vm.checkout),callback:function ($$v) {_vm.checkout=$$v},expression:"checkout"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v("Preu propietari")]),_c('b-form-input',{attrs:{"number":""},model:{value:(_vm.ownerPrice),callback:function ($$v) {_vm.ownerPrice=$$v},expression:"ownerPrice"}})],1)],1),_c('b-row',{staticClass:"mb-1 d-flex justify-content-between justify-content-sm-end"},[_c('b-col',{attrs:{"cols":"6","sm":"auto"}},[_c('b-button',{attrs:{"block":"","variant":"light"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel·la ")])],1),_c('b-col',{attrs:{"cols":"6","sm":"auto"}},[_c('b-button',{attrs:{"block":"","variant":"primary","disabled":!_vm.accommodation || !_vm.checkin || !_vm.checkout || !_vm.ownerPrice},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guarda ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }