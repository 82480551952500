<template>
  <div id="add-booking-page">
    <b-card title="Una reserva">
      <b-row>
        <b-col class="d-flex text-center align-items-center">
          <b-button variant="primary" @click="onImportButtonClicked">
            Importa una reserva
          </b-button>
          <b-button
            v-if="isAdmin"
            variant="primary"
            @click="$bvModal.show('new-fictitious-booking-modal')"
            class="ml-1"
          >
            Genera una reserva especial
          </b-button>
          <!-- <b-button
            variant="primary"
            @click="onCreateButtonClicked"
          >
            Crea una nova reserva
          </b-button> -->
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Múltiples reserves">
      <b-row>
        <b-col>
          <b-button variant="primary" @click="onImportMultipleButtonClicked">
            Importa múltiples reserves
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="new-fictitious-booking-modal"
      title="Introdueix les dates per la nova reserva especial"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <fictitious-booking-form
        @created="onFictitiousBookingCreated"
        @error="onCreateFictitiousBookingError"
        @cancel="$bvModal.hide('new-fictitious-booking-modal')"
      />
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BButton, BModal } from "bootstrap-vue";
import FictitiousBookingForm from "@/views/bookings/new-booking/components/FictitiousBookingForm.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    FictitiousBookingForm,
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
  },
  methods: {
    onCreateButtonClicked() {
      this.$router.push({
        name: "foravila-new-booking",
      });
    },
    onFictitiousBookingCreated(localizator) {
      this.$bvModal.hide("new-fictitious-booking-modal");
      this.$router.push({
        name: "foravila-booking-view",
        params: { localizator },
      });
    },
    onCreateFictitiousBookingError() {
      notifyError(
        "Error",
        "Hi ha hagut algun error al intentar crear la reserva especial, intenta-ho de nou o contacta amb en Pedro si el problema persisteix"
      );
    },
    onImportButtonClicked() {
      this.$router.push({
        name: "foravila-import-booking",
      });
    },
    onImportMultipleButtonClicked() {
      this.$router.push({
        name: "foravila-import-bookings",
      });
    },
  },
};
</script>
