<template>
  <div id="fictitious-booking-form">
    <!-- FORM -->
    <b-row class="mb-2">
      <!-- ACCOMMODATION -->
      <b-col cols="12" class="mb-1">
        <h6>Allotjament</h6>
        <v-select
          v-model="accommodation"
          :options="accommodationOptions"
          :reduce="(option) => option.value"
          :loading="loadingAccommodations"
          @click.stop=""
        />
      </b-col>

      <!-- CHECK-IN -->
      <b-col cols="12" sm="6" class="mb-1">
        <h6>Entrada</h6>
        <b-form-datepicker
          v-model="checkin"
          placeholder="Data d'entrada"
          :locale="locale"
          :start-weekday="1"
          :show-decade-nav="false"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          hide-header
        />
      </b-col>

      <!-- CHECK OUT -->
      <b-col cols="12" sm="6">
        <h6>Sortida</h6>
        <b-form-datepicker
          v-model="checkout"
          placeholder="Data de sortida"
          :locale="locale"
          :start-weekday="1"
          :show-decade-nav="false"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          :min="checkin"
          hide-header
        />
      </b-col>

      <!-- OWNER PRICE -->
      <b-col cols="12">
        <h6>Preu propietari</h6>
        <b-form-input v-model="ownerPrice" number />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button
          block
          variant="primary"
          :disabled="!accommodation || !checkin || !checkout || !ownerPrice"
          @click="save()"
        >
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BFormInput,
} from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import vSelect from "vue-select";
import axios from "@/axios";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BFormInput,
    vSelect,
  },
  data() {
    return {
      accommodation: null,
      checkin: null,
      checkout: null,
      ownerPrice: null,
    };
  },
  computed: {
    loadingAccommodations() {
      return this.$store.getters["accommodations/loading"];
    },
    accommodations() {
      return this.$store.getters["accommodations/accommodations"];
    },
    accommodationOptions() {
      if (this.accommodations.length === 0) {
        return [];
      }

      return this.accommodations.map((a) => ({
        label: a.name,
        value: a["@id"],
      }));
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  created() {
    if (!this.accommodations?.length < 10) {
      this.fetchAccommodations();
    }
  },
  methods: {
    fetchAccommodations() {
      this.$store
        .dispatch("accommodations/fetchAccommodations", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchAccommodations.title"),
            this.$t("errors.fetchAccommodations.description")
          )
        );
    },
    cancel() {
      this.$emit("cancel");
    },
    save() {
      // Show loader
      this.$store.dispatch("app/setLoading", true);

      // API call
      axios
        .post("/new_fictitious_booking", {
          accommodation: this.accommodation,
          checkin: this.checkin,
          checkout: this.checkout,
          ownerPrice: this.ownerPrice * 100,
        })
        .then((response) => {
          if (response.status === 201) {
            this.$emit("created", response.data.localizator);
          } else {
            console.error(response);
            this.$emit("error");
          }
        })
        .catch((error) => {
          console.error(error);
          this.$emit("error");
        });

      // Hide loader
      this.$store.dispatch("app/setLoading", false);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#fictitious-booking-form {
  .custom-control-input ~ .custom-control-label {
    margin-left: 5px;
  }
}
</style>
